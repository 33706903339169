














































































import { AnonymousInvestorPlatformClient, InvestorPlatformClient } from '@/client'
import { AxiosError } from 'axios'
import { Component, Vue } from 'vue-property-decorator'
import * as EmailValidator from 'email-validator'
import { REQUIRED_TOS_KEY } from '@/constants'

@Component({})
export default class Login extends Vue {
  email: string = ''
  password: string = ''
  error: string = ''
  resetPasswordEmail: string = ''
  resetPasswordAlert = {
    type: '',
    message: ''
  }

  async login() {
    const client = new InvestorPlatformClient(process.env.VUE_APP_API_ENDPOINT!)
    if (!EmailValidator.validate(this.email)) {
      this.error = 'Invalid email'
      return
    }

    if (!this.password) {
      this.error = 'Password cannot be empty'
      return
    }

    try {
      localStorage.removeItem(REQUIRED_TOS_KEY)
      const requiredTos: number = await client.login(this.email, this.password)
      client.saveToken()

      if (requiredTos !== null) {
        localStorage.setItem(REQUIRED_TOS_KEY, requiredTos.toString())
        this.$router.push(`/tos/${requiredTos}`)
      } else {
        this.$router.push('/app')
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response?.status == 401) {
          this.error = 'Incorrect username or password'
        } else if (e.response?.status == 404) {
          this.error = 'User not found'
        } else if (
          e.response?.status == 403 &&
          e.response?.data.message == 'EMAIL_NOT_CONFIRMED'
        ) {
          // TODO: give option to send confirmation email again
          this.error = 'Email not confirmed'
        } else if (
          e.response?.status == 403 &&
          e.response?.data.message == 'USER_NOT_APPROVED'
        ) {
          this.error = 'Account not yet approved'
        }
      }
      
      throw e
    }
  }

  async resetPassword() {
    const client = new AnonymousInvestorPlatformClient(process.env.VUE_APP_API_ENDPOINT!)
    if (!EmailValidator.validate(this.resetPasswordEmail)) {
      this.resetPasswordAlert = {
        type: 'error',
        message: 'Invalid email'
      }
      return
    }

    await client.startResetPassword(this.resetPasswordEmail)
    this.resetPasswordAlert = {
      type: 'info',
      message: 'Check your email for a password reset link'
    }
  }
}
